<template>
  <div class="page_bg">
    <div class="subjectPage bsbj_bg">
      <!-- 顶部导航 -->
      <div class="search_top  flex-c-s-c">
        <div class="ser_search  flex-r-s-c" style="background: none;">
          <div />
          <div class="flex-r-s-s" @click="backPath">
            <van-icon name="arrow-left" size="0.5rem" color="#ffffff" style="margin-left:0.3rem" />
            <div style="padding-left:0.1rem;color: #fff;font-size: 0.37rem;">返回</div>
          </div>
          <div />
        </div>
      </div>
      <!-- 标签栏滑动 一级 -->
      <div class="subject_tab">
        <van-tabs v-model="tabs_active01">
          <van-tab v-for="(level01,level01_index) in levelList01" :key="level01_index">
            <template #title>
              <div :class="level01_checked === parseInt(level01.icon) ? 'tab_items flex-c-c-c tab_items_actived':'tab_items flex-c-c-c'"
                   @click="choose01(level01)"
              >
                <van-image width="1.6rem" height="1.17rem" :src="level01.img" />
                <div class="tab_items_name">{{ level01.name }}</div>
                <div v-if="level01_checked === parseInt(level01.icon)" class="tab_triangle" />
              </div>
            </template>
          </van-tab>
        </van-tabs>
      </div>
      <!-- 标签栏滑动 二级 -->
      <div v-if="levelList02.length > 1" class="subject_tab2">
        <div v-if="showBoard" class="top_classify board_box flex-r-s-s"
             style="flex-wrap: wrap;padding-left:0.37rem;background: #ffffff;"
        >
          <div v-for="(level02,level02_index) in levelList02" :key="level02_index"
               style="margin-bottom:0.32rem;margin-right:0.21rem"
          >
            <div :class="level02_checked === level02_index ? 'top_classify_item top_classify_item_actived':'top_classify_item'"
                 @click="choose02(level02,level02_index)"
            >
              <div class="tab_items_name">{{ level02.name }}</div>
            </div>
          </div>
          <div class="board_close flex-r-c-c" @click="showBoard = false">
            <div style="padding-right:0.1rem">点击收起</div>
            <van-icon name="arrow-up" size="0.4rem" />
          </div>
        </div>
        <div v-else class="top_classify flex-r-s-c">
          <div v-if="levelList02.length === 3 || levelList02.length === 2" class="board_box flex-r-s-s"
               style="flex-wrap: wrap;padding-left:0.37rem;background: #ffffff;overflow-y: hidden;overflow-x: scroll;"
          >
            <div v-for="(level02,level02_index) in levelList02" :key="level02_index"
                 style="margin-bottom:0.32rem;margin-right:0.21rem"
            >
              <div :class="level02_checked === level02_index ? 'top_classify_item top_classify_item_actived':'top_classify_item'"
                   @click="choose02(level02,level02_index)"
              >
                <div class="tab_items_name">{{ level02.name }}</div>
              </div>
            </div>
          </div>
          <van-tabs v-else v-model="tabs_active02" style="width:85%;" :ellipsis="false" :swipe-threshold="2">
            <van-tab v-for="(level02,level02_index) in levelList02" :key="level02_index">
              <template #title>
                <div :class="level02_checked === level02_index ? 'top_classify_item top_classify_item_actived':'top_classify_item'"
                     @click="choose02(level02,level02_index)"
                >
                  <div class="tab_items_name">{{ level02.name }}</div>
                </div>
              </template>
            </van-tab>
          </van-tabs>
          <div v-if="levelList02.length > 3" style="border-right:0.07rem rgb(233,233,233) solid;height:0.85rem" />
          <van-icon v-if="levelList02.length > 3" name="arrow-down" style="margin-right:0.1rem;padding-left: 0.2rem;"
                    size="0.5rem" color="rgb(164, 164, 164)" @click="showBoard = true"
          />
          <div>&nbsp;</div>
        </div>
      </div>
      <!-- 商品列表区域  -->
      <div :id="levelList02.length <=1 ?'subject_pro_list':''" class="pro_list subject_pro_list"
           style="z-index:888 !important;"
      >
        <MyscrollList ref="myList01" :loading="listLoading" :list="proList" @myload="proload">
          <template #default="slotProps">
            <proCard01 :info="slotProps.item" />
          </template>
        </MyscrollList>
      </div>
    </div>
  </div>
</template>

<script>
import './index.scss'
import { searchMallProductRecommend, tagCategoryList } from '@/api/pro'
import { bsbj_level01 } from '@/utils/constants'
import MyscrollList from '@/components/MyscrollList'
import proCard01 from '@/components/pro_card_01'
// 组件加载
export default {
  name: 'SubjectPageBsbj',
  components: { MyscrollList, proCard01 },
  data() {
    return {
      tabs_active01: 0,
      levelList01: bsbj_level01,
      level01_checked: 21,
      tabs_active02: 0,
      levelList02: [],
      level02_checked: 0,
      showBoard: false,
      // 商品列表
      proSearch: { pageNum: 1, pageSize: 10, tagId: 335, tagCategoryId: '' },
      proList: [],
      listLoading: false
    }
  },
  activated() {
    console.log('keepalive 进入SubjectPageBsbj')
    if (sessionStorage.getItem('listJump')) {
      console.log('keepalive listJump')
      window.scrollTo({
        top: 0, // 滚动到顶部
        behavior: 'smooth' // 平滑滚动
      })
      sessionStorage.removeItem('listJump')
      this.proSearch.pageNum = 1; this.proList = []; this.level01_checked = 21; this.tabs_active01 = 0
      this.$nextTick(() => { this.serClassfily_level2('1810869873648209920') })
    }
  },
  deactivated() {
    console.log('keepalive 离开SubjectPageBsbj')
  },
  created() {
    this.proSearch.pageNum = 1; this.proList = []; this.level01_checked = 21; this.tabs_active01 = 0
    this.$nextTick(() => { this.serClassfily_level2('1810869873648209920') })
  },
  methods: {
    backPath() { window.history.back() },
    serClassfily_level2(id) { // 获取二级分类
      this.levelList02 = []
      tagCategoryList({ id: id }).then(res => {
        this.level02_checked = 0; this.levelList02 = res.data
        this.proSearch.pageNum = 1; this.proSearch.tagCategoryId = res.data[0].id; this.proList = []
        this.getProList()
      })
    },
    choose01(item) {
      this.level01_checked = parseInt(item.icon)
      this.serClassfily_level2(item.id)
    },
    choose02(item, index) {
      this.level02_checked = index; this.tabs_active02 = parseInt(index)
      this.proSearch.pageNum = 1; this.proSearch.tagCategoryId = item.id; this.proList = []; this.showBoard = false
      this.getProList()
    },
    getProList() {
      this.$toast.loading({ message: '加载中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      searchMallProductRecommend(this.proSearch).then(res => {
        this.$refs.myList01.load_empty('list', false)
        this.$toast.clear()
        this.$refs.myList01.load_loading(false)
        this.proList = this.proList.concat(res.data.data)
        if (parseInt(res.data.total) === 0) { this.$refs.myList01.load_empty('list', true) } else {
          if (res.data.data.length === 0) { this.$refs.myList01.load_finished(true) } // 数据全部加载完成
        }
      })
    },
    proload() {
      this.$refs.myList01.load_loading(true)
      this.$utils.debounce(() => {
        this.proSearch.pageNum = this.proSearch.pageNum + 1
        this.getProList()
        // 加载状态结束
      }, 50)()
    }
  }
}
</script>
